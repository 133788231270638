import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { SEO, ImageAndText, SeeAlso } from '../components/UI'
import { CrystalVerticalAccordion } from '../components/Crystal'
import { useImageSabores } from '../hooks'

import styles from './CrystalSabores.module.css'

export default function CrystalSabores() {
  const { image, seeAlsoImage } = useStaticQuery(
    graphql`
      query CrystalSaboresPage {
        image: file(relativePath: { eq: "crystal-sabores/sabores.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seeAlsoImage: file(relativePath: { eq: "crystal-sabores/gas-sem-gas.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const imageSabores = useImageSabores()

  return (
    <>
      <SEO title='Crystal Sabores' />

      <ImageAndText
        image={image}
        alt='Garrafas de Crystal Sabores'
        title='Crystal Sabores'
        renderSubtitle={() => (
          <>
            água, gás
            <br />e aroma
            <br />
            natural
          </>
        )}
        classnames={{
          container: styles.container,
          imageWrapper: styles.imageWrapper,
          textWrapper: styles.textWrapper,
          text: styles.text
        }}
      >
        <p>
          Combinação simples que faz toda diferença: Água com gás Crystal e toques de aromas naturais. Nada de
          adoçantes, cores ou sabores artificiais. Muito menos conservantes.
        </p>
      </ImageAndText>

      <CrystalVerticalAccordion items={imageSabores} />

      <SeeAlso
        title='conheça também'
        to='/agua-crystal'
        linkText='Água Crystal'
        description={`Do jeitinho que é retirada da fonte, a água mineral
        Crystal chega até você. Também na versão com gás.`}
        image={seeAlsoImage.childImageSharp.fluid}
      />
    </>
  )
}
